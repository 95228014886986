import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PageLayout from 'components/layouts/page/page.layout';
import PageSection from 'ui-kit/page-section/page-section';
import { Col, Row } from 'react-bootstrap';
import DrugFormularyTable from 'components/drug-formulary-table/drug-formulary-table.component';

const DrugListProvider = ({ pageContext, data }: { pageContext: any; data: DrugFormularyDataQuery }) => {
    const { t } = useTranslation();
    const metaData = 'test'; // Use the AC Code to get the title from translation { nodeTitle: activeDrug?.drugName };
    return (
        <PageLayout metaData={metaData}>
            <PageSection key={`page_section_0`}>
                <Row>
                    <Col sm={12} lg={{ span: 8, offset: 2 }}>
                        <h1 className="h2">
                            {t('pages.drugsList.title', {
                                formularyTitle:
                                    pageContext.acCode === 'BRD01' ? t('pages.drugsList.BRD01') : pageContext.acCode
                            })}
                        </h1>
                        <DrugFormularyTable formulary={data.formulary?.nodes} />
                    </Col>
                </Row>
            </PageSection>
        </PageLayout>
    );
};

export default DrugListProvider;

export const query = graphql`
    query DrugFormularyData($language: String!, $acCode: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        formulary: allFormularyDrug(filter: { acCode: { eq: $acCode } }) {
            nodes {
                drugName: onSaleDrugName
                onSaleDrugName
                brandDrugName
                brandNameCode
                genericProductCode
                dosageForms {
                    dosageForm
                    strengths {
                        fullStrength
                        gpi
                        strength
                        strengthUnit
                    }
                }
            }
        }
    }
`;
